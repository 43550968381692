import { Select as muiSelect, MenuItem as muiMenuItem, styled } from "@mui/material"

interface ISelect {
  size?: string
  height?: string
  minheight?: string
  weight?: string
  errormessage?: string
}

export const Select = styled(muiSelect)<ISelect>(({ theme, size, height, minheight, weight, errormessage }) => {
  const style = {
    fontSize: size || "16px",
    fontWeight: weight || "normal",
    borderRadius: "12px !important",
    background: theme.palette.background.default,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "12px"
    },
    "& .MuiSelect-select": {
      alignItems: "center",
      display: "flex",
      boxSizing: "border-box",
      height: `${height || "52px"} !important`,
      minHeight: minheight || "unset",
      padding: "8px 14px",
      borderRadius: "12px !important",
      border: `1px solid ${theme.border.gray3}`,
      margin: 0,
      zIndex: 2
    }
  }
  if (errormessage) {
    style.background = theme.palette.background.red3
    style["& .MuiSelect-select"] = {
      ...style["& .MuiSelect-select"],
      border: `solid 1px ${theme.border.red2}`
    }
  }
  return style
})

interface ISelectItem {
  choosed: number
  choosedstyle?: { [key: string]: string }
}

export const SelectItem = styled(muiMenuItem)<ISelectItem>(({ theme, choosed, choosedstyle }) => {
  const style = {
    display: "flex",
    alignItems: "center",
    padding: "8px 14px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "normal",
    color: theme.palette.text.black2,
    wordBreak: "break-word" as const,
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.background.yellow2
    }
  }
  if (choosed) {
    style.color = theme.palette.text.yellow3
    style.fontWeight = choosedstyle?.weight || "600"
  }
  return style
})
