import { styled } from "@mui/material"

export const FooterWrapper = styled("footer")(({ theme }) => ({
  position: "absolute" as const,
  bottom: "0px",
  background: "transparent",
  width: "100%",
  height: "84px",
  borderTop: `1px solid ${theme.palette.background.gray2}`,
  display: "flex",
  alignItems: "center",
  "& > a": {
    alignItems: "center",
    display: "flex"
  },
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
    gap: "24px"
  },
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
    rowGap: "4px",
    columnGap: "16px",
    justifyContent: "flex-start",
    padding: "20px 16px"
  }
}))

export const PolicyText = styled("p")(({ theme }) => ({
  color: theme.palette.text.black3,
  fontSize: "14px",
  fontWeight: "normal",
  cursor: "pointer",
  margin: "0px",
  "&:hover": {
    borderBottom: `1px solid ${theme.palette.text.black3}`
  }
}))
