// Components
// Libs
import { styled } from "@mui/material"
// Styles

interface IPicker {
  value?: number
}

export const Picker = styled("div")<IPicker>(({ theme, value }) => {
  const style = {
    width: "48px",
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "24px",
    fontWeight: 400,
    color: theme.palette.text.black2,
    borderRadius: "12px",
    border: `1px solid ${theme.border.gray3}`,
    lineHeight: "1px",
    cursor: "pointer",
    pointerEvents: "auto" as React.CSSProperties["pointerEvents"],
    userSelect: "none" as const,
    background: theme.palette.background.transparent
  }
  if (value !== undefined && value <= 0) {
    style.pointerEvents = "none" as React.CSSProperties["pointerEvents"]
    style.background = theme.palette.background.gray2
  } else {
    style.color = theme.palette.text.yellow3
  }
  return style
})

export const NumberText = styled("p")(({ theme }) => ({
  height: "48px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  fontWeight: 400,
  borderRadius: "12px",
  border: `1px solid ${theme.border.gray3}`,
  color: theme.palette.text.black3,
  margin: "0px 12px",
  lineHeight: "1px",
  background: theme.palette.background.default,
  [theme.breakpoints.up("sm")]: {
    width: "170px"
  },
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 24px - 80px)"
  }
}))
