import { Button, styled } from "@mui/material"

interface IButtonBase {
  height?: string
  width?: string
  size?: string
  weight?: string
  borderradius?: string
}

const ButtonBase = styled(Button)<IButtonBase>(({ theme, height, width, size, weight, borderradius }) => {
  const style = {
    fontSize: size || "16px",
    fontWeight: weight || "500",
    height: height || "auto",
    width: width || "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: borderradius || "28px",
    boxShadow: "none !important",
    whiteSpace: "nowrap" as const,
    "&.Mui-disabled": {
      color: theme.palette.text.white,
      backgroundColor: theme.palette.background.gray5
    }
  }
  return style
})

export default ButtonBase
