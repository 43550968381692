// Put reusable types/interfaces in here
export interface IPerson {
  name: string
}

export interface ILabel {
  en: string | null
  "zh-HK": string | null
  "zh-CN"?: string | null
}

export type TText = {
  en: string
  "zh-HK": string
}

export type TImage = {
  en: {
    id: "string"
  }
  "zh-HK": {
    id: "string"
  }
}

export enum DiscountStatus {
  VALID = "VALID",
  INVALID = "INVALID",
  DRAFT = "DRAFT"
}

export enum ObjectFit {
  CONTAIN = "contain",
  COVER = "cover",
  FILL = "fill",
  NONE = "none",
  SCALE_DOWN = "scale-down"
}