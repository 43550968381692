import { FC } from "react"
// Components
import { Box } from "@mui/material"
import { Row } from "@components/common"
import { Picker, NumberText } from "./number-picker.styles"
// Libs
// Styles

interface INumberPicker {
  value: number
  handleChange?: (result: number) => void
}

const NumberPicker: FC<INumberPicker> = ({ handleChange, value }) => {
  const handleChangeClick = (type: string) => {
    let result = 0
    if (type === "add") {
      result = value + 1
    } else {
      result = value - 1
    }
    handleChange && handleChange(result)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Row>
        <Picker className="picker" value={value} onClick={() => handleChangeClick("minus")}>
          {"－"}
        </Picker>
        <NumberText className="text">{value}</NumberText>
        <Picker className="picker" onClick={() => handleChangeClick("add")}>
          {"＋"}
        </Picker>
      </Row>
    </Box>
  )
}

export default NumberPicker
