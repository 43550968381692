import { FC } from "react"
import { useRouter } from "next/router"
// Components
import { Text } from "@components/common"
import { FormControl } from "@mui/material"
import { Select, SelectItem } from "./select-base.styles"
import ImgBase from "@components/images/image-base"
// Libs
import { useTranslation } from "next-i18next"
import { makeStyles } from "@mui/styles"
import { Theme } from "@mui/material/styles"
import { ILabel } from "@constants"
// Styles

interface ISelectBase {
  width?: string
  height?: string
  errormessage?: string
  disabled?: boolean
  placeholder: string
  list: { id: string; label: string }[]
  multiple?: boolean
  value: string
  onChange: any
}

const SelectBase: FC<ISelectBase> = ({
  width = "100%",
  height = "52px",
  errormessage,
  disabled,
  placeholder,
  list,
  multiple,
  value,
  onChange,
  ...props
}) => {
  const classes = useStyles()

  return (
    <FormControl sx={{ width, height }}>
      <Select
        {...props}
        MenuProps={{ classes: { list: classes.list } }}
        value={value}
        onChange={onChange}
        displayEmpty={true}
        errormessage={errormessage}
        IconComponent={() => (
          <ImgBase
            cursor="pointer"
            width="24px"
            src="ic-textfield-dropdown-arrow@3x"
            alt="arrow button"
            sx={{ position: "absolute", right: "16px", zIndex: 1 }}
          />
        )}
        renderValue={value ? undefined : () => <Text size="16px">{placeholder}</Text>}
      >
        {list?.map((item, index) => (
          <SelectItem key={`select_item_${item.label}_${index}`} value={item.id} choosed={value === item.id ? 1 : 0}>
            {GetLabel(item.label)}
          </SelectItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const useStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: "8px 0px",
    background: theme.palette.background.default,
    maxHeight: "300px",
    "& .MuiMenuItem-root": {
      backgroundColor: "transparent"
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: theme.palette.background.yellow2
    },
    "& .MuiSelect-select::placeholder": {
      color: "red"
    }
  }
}))

export const GetLabel: (label: string | ILabel) => string = (label) => {
  const { locale = "en" } = useRouter()
  const { t } = useTranslation(["common"])
  if (typeof label === "string") {
    return t(label)
  }
  if (typeof label === "object") {
    return label[locale]
  }
  return ""
}

export default SelectBase
