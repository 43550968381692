import { styled } from "@mui/material"

export const HeaderWrapper = styled("header")(({ theme }) => ({
  display: "flex",
  zIndex: 2,
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  background: "transparent",
  [theme.breakpoints.up("sm")]: {
    height: "80px",
    position: "absolute",
    paddingLeft: "32px",
    paddingRight: "44px"
  },
  [theme.breakpoints.down("sm")]: {
    height: "64px",
    position: "relative",
    padding: "23px 16px"
  }
}))

interface ITitleText {
  color?: string
  weight?: string
}

export const TitleText = styled("p")<ITitleText>(({ theme, color, weight }) => ({
  margin: "0px",
  fontSize: "22px",
  fontWeight: weight || "normal",
  color: theme.palette.text[color || "blue2"],
  [theme.breakpoints.up("sm")]: {
    letterSpacing: "-0.3px"
  },
  [theme.breakpoints.down("sm")]: {
    letterSpacing: "0.6px"
  }
}))
