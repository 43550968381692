import { styled } from "@mui/material"

export const LayoutWrapper = styled("div")({
  position: "relative",
  height: "100%",
  minHeight: "100vh",
  width: "100%"
})

export const MainWrapper = styled("main")(({ theme }) => ({
  position: "relative",
  minHeight: "100vh",
  width: "100%",
  justifyContent: "center",
  alignItems: "flex-start",
  display: "flex",
  overflowY: "auto",
  backgroundImage: `linear-gradient(to bottom, #e4f0f4 0%, #fff 560px, #fff calc(100% - 240px), #e4f0f4);`,
  [theme.breakpoints.up("sm")]: {
    padding: "80px 10px 84px"
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0px 10px 84px"
  }
}))

export const CircleBackground = styled("div")(({ theme }) => ({
  position: "absolute",
  borderRadius: "270px",
  width: "540px",
  maxWidth: "80vw",
  height: "540px",
  maxHeight: "80vw",
  top: "120px",
  margin: "0px 450px",
  WebkitFilter: "blur(75px)",
  filter: "blur(75px)",
  backgroundColor: `${theme.palette.background.default}`
}))
