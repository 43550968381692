import { FC } from "react"
// Components
import Box from "@mui/material/Box"
import { SelectItem } from "./select-base.styles"
// Libs
import { languageOptions } from "@constants"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
// Styles

interface ISelectLanguage {}

const SelectLanguage: FC<ISelectLanguage> = ({}) => {
  const { t } = useTranslation(["common"])
  const router = useRouter()
  const { locale, pathname, asPath, query } = router

  const handleLanguageClick = (language) => {
    if (locale !== language) {
      router.push({ pathname, query }, asPath, { locale: language })
    }
  }

  return (
    <Box sx={boxStyle}>
      {languageOptions.map((item) => (
        <SelectItem
          key={`language_${item.id}`}
          choosed={locale === item.id ? 1 : 0}
          choosedstyle={{ weight: "500" }}
          onClick={() => handleLanguageClick(item.id)}
        >
          {t(item.label)}
        </SelectItem>
      ))}
    </Box>
  )
}

const boxStyle = {
  position: "absolute",
  right: "0px",
  width: "170px",
  padding: "8px 0px",
  background: "white",
  borderRadius: "12px",
  border: "solid 1px #eaeaea",
  boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.08)"
}

export default SelectLanguage
