import { FC, MouseEvent } from "react"
// Components
// Libs
import { styled } from "@mui/material"
import { ObjectFit } from "@constants"
import isUrl from "@utils/isUrl"
import assetPrefix from "@prefix"
// Styles

interface IImage {
  width?: string
  height?: string
  cursor?: string
  objectfit?: ObjectFit
}

const Image = styled("img")<IImage>(({ width, height, cursor, objectfit }) => {
  const style = {
    width: width || "32px",
    height: height || width || "32px",
    cursor: cursor || "unset",
    objectFit: objectfit || ObjectFit.CONTAIN
  }
  return style
})

interface IImageBase extends IImage {
  src: string
  alt: string
  sx?: any
  className?: any
  onClick?: () => void
  onMouseDown?: (event: MouseEvent<HTMLImageElement>) => void
}

const ImgBase: FC<IImageBase> = (props) => {
  const { src, alt, ...rest } = props
  const srcCustom = `${src}.png`

  return isUrl(src) ? (
    <Image src={src} alt={alt} {...rest} />
  ) : (
    <Image src={`${assetPrefix}images/${srcCustom}`} alt={alt || src} {...rest} />
  )
}

export default ImgBase
