import { FC, useState, useRef, useEffect } from "react"
// Components
import ImgBase from "@components/images/image-base"
import { HeaderWrapper, TitleText } from "./index.styles"
import { Row } from "@components/common"
import { SelectLanguage } from "@components/selects"
// Libs
import { useTranslation } from "next-i18next"
import useClickOutside from "@hooks/useClickOutside"
// Styles
import Box from "@mui/material/Box"

const Header: FC = () => {
  const { t } = useTranslation(["common"])

  const [open, setOpen] = useState(false)
  const languageRef = useRef<HTMLDivElement>(null)

  useClickOutside(languageRef, () => setOpen(false), [open])

  useEffect(() => {
    window.addEventListener("scroll", isSticky)
    return () => {
      window.removeEventListener("scroll", isSticky)
    }
  })

  const isSticky = () => {
    const header = document.getElementById("header")
    if (header) {
      if (window.pageYOffset > header.offsetHeight) {
        header.classList.add("is-sticky")
      } else {
        header.classList.remove("is-sticky")
      }
    }
  }

  return (
    <HeaderWrapper id="header">
      <Row gap="4px">
        <TitleText weight="bold">{t("HEADER_TITLE_TrailWatch")}</TitleText>
        <TitleText color="blue3">{t("HEADER_TITLE_Event")}</TitleText>
      </Row>

      <Box sx={{ position: "relative" }}>
        <div onClick={() => setOpen(true)} ref={languageRef}>
          <ImgBase cursor="pointer" width="24px" src="buttons-language@3x" alt="change language button" />
        </div>
        {open && <SelectLanguage />}
      </Box>
    </HeaderWrapper>
  )
}

export default Header
