import { FC, useState, useRef, useReducer } from "react"
import { useRouter } from "next/router"
// Components
import { Row, Text } from "@components/common"
import { FormControl } from "@mui/material"
import { useStyles } from "./select-base"
import { Select, SelectItem } from "./select-base.styles"
import { NumberPicker } from "@components/pickers"
import { NumberPickerBox, SelectedItem, ButtonBox } from "./select-quantity.styles"
import { ButtonBase } from "@components/buttons"
import ImgBase from "@components/images/image-base"
// Libs
import { useTranslation } from "next-i18next"
import { TText } from "@constants"
import { cloneDeep } from "lodash"
import mainReducer, { resetData, pickerChange } from "./reducers/select-quantity"
import useClickOutside from "@hooks/useClickOutside"
// Styles

export type TValue = {
  id: number
  quantity: number
  name: TText
}[]

interface ISelectQuantity {
  width?: string
  height?: string
  minheight?: string
  errormessage?: string
  disabled?: boolean
  placeholder: string
  list: { id: number; name: TText }[]
  value: TValue
  onChange: any
}

const SelectQuantity: FC<ISelectQuantity> = ({
  width = "100%",
  height = "52px",
  minheight = "unset",
  errormessage,
  disabled,
  placeholder,
  list,
  value,
  onChange,
  ...props
}) => {
  const { locale = "en" } = useRouter()
  const { t } = useTranslation(["common"])
  const classes = useStyles()
  const selectQtyRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)
  const [state, reducerDispatch] = useReducer(mainReducer, cloneDeep(value))

  useClickOutside(selectQtyRef, () => setOpen(false), [open])

  const handleConfirmCancel = (type: "cancel" | "confirm") => {
    switch (type) {
      case "cancel":
        break
      case "confirm":
        onChange && onChange(state)
        break
    }
  }

  return (
    <FormControl sx={{ width, height }}>
      <Select
        {...props}
        open={open}
        onClick={() => setOpen(true)}
        onOpen={() => reducerDispatch(resetData(cloneDeep(value) || []))}
        ref={selectQtyRef}
        height={height}
        minheight={height}
        MenuProps={{ classes: { list: classes.list } }}
        displayEmpty={true}
        errormessage={errormessage}
        IconComponent={() => (
          <ImgBase
            cursor="pointer"
            width="24px"
            src="ic-textfield-dropdown-arrow@3x"
            alt="arrow button"
            sx={{ position: "absolute", right: "16px" }}
          />
        )}
        renderValue={() => <GetRenderValue value={value} placeholder={placeholder} />}
      >
        <div>
          {list?.map((item, index) => (
            <SelectItem
              key={`select_item_${item.id}_${index}`}
              value={item.id}
              choosed={0}
              onClick={(event) => event.stopPropagation()}
            >
              <Row justifyContent={"space-between"} sx={{ width: "100%" }}>
                {item.name[locale]}
                <NumberPickerBox>
                  <NumberPicker
                    value={getNum(state, item.id)}
                    handleChange={(result) => reducerDispatch(pickerChange({ item, result }))}
                  />
                </NumberPickerBox>
              </Row>
            </SelectItem>
          ))}
          <ButtonBox>
            <ButtonBase
              color="primary"
              variant="outlined"
              width="106px"
              height="48px"
              borderradius="24px"
              sx={{ borderColor: "#eaeaea" }}
              onClick={() => handleConfirmCancel("cancel")}
            >
              {t("TXT_COMMON_Cancel")}
            </ButtonBase>
            <ButtonBase
              color="primary"
              variant="contained"
              width="106px"
              height="48px"
              borderradius="24px"
              onClick={() => handleConfirmCancel("confirm")}
            >
              {t("TXT_COMMON_Confirm")}
            </ButtonBase>
          </ButtonBox>
        </div>
      </Select>
    </FormControl>
  )
}

const GetRenderValue: FC<{ value: TValue; placeholder: string }> = ({ value, placeholder }) => {
  const { locale = "en" } = useRouter()

  if (value.length) {
    return (
      <Row gap="4px" sx={{ flexWrap: "wrap", paddingRight: "10px" }}>
        {value.map((selectedItem) => (
          <SelectedItem key={`selectedItem_${selectedItem.id}`}>
            <Text size="16px" weight="500" wordbreak="break-word">
              {selectedItem.name[locale]}
            </Text>
            <Text size="16px" sx={{ marginLeft: "3px" }}>{`x${selectedItem.quantity}`}</Text>
          </SelectedItem>
        ))}
      </Row>
    )
  }
  return (
    <Text size="16px" color="gray4">
      {placeholder}
    </Text>
  )
}

const getNum = (value: TValue, itemId: number) => {
  const findItem = value.find((item: { id: number; quantity: number; name: TText }) => item.id === itemId)
  return findItem?.quantity || 0
}

export default SelectQuantity
