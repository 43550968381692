interface IdocType {
  [key: string]: { id: string; label: string }
}

export const languageOptions = [
  { id: "en", label: "OPTION_COMMON_English" },
  { id: "zh-HK", label: "OPTION_COMMON_TC" }
]

export const genderOptions = [
  { id: "M", label: "OPTION_COMMON_Male" },
  { id: "F", label: "OPTION_COMMON_Female" }
]

export const participantNumberOptions = [
  { id: "2", label: "2" },
  { id: "3", label: "3" },
  { id: "4", label: "4" },
  { id: "5", label: "5" }
]

export const donationOptions = [
  { id: "100", label: "$100" },
  { id: "250", label: "$250" },
  { id: "500", label: "$500" },
  { id: "custom", label: "OPTION_COMMON_Custom_Amount" }
]
export const donationOptionsDoc: IdocType = donationOptions.reduce((obj, currnt) => ({ ...obj, [currnt.id]: currnt }), {})