import { styled } from "@mui/material"

export const NumberPickerBox = styled("div")(({ theme }) => ({
  width: "136px",
  "& .picker": {
    width: "36px",
    height: "36px",
    border: "none",
    borderRadius: "8px",
    background: "transparent"
  },
  "& .text": {
    height: "36px",
    width: "48px",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 8px",
      width: `calc(100% - 16px - 72px)`
    }
  }
}))

export const SelectedItem = styled("div")(({ theme }) => ({
  display: "inline-flex",
  padding: "4px 8px",
  alignItems: "center",
  background: theme.palette.background.green2,
  borderRadius: "8px"
}))

export const ButtonBox = styled("div")(({}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "28px 14px 24px",
  gap: "12px"
}))
