import { createSlice } from "@reduxjs/toolkit"
import type { TText } from "@constants"
import type { TValue } from "../select-quantity"

const mainReducer = createSlice({
  name: "mainReducer",
  initialState: [] as TValue,
  reducers: {
    resetData(state: TValue, { payload }: { payload: TValue }) {
      return payload
    },
    pickerChange(state: TValue, { payload }: { payload: { item: { id: number; name: TText }; result: number } }) {
      const { item, result } = payload

      if (result === 0) {
        state = state.filter((_item) => _item.id !== item.id)
        return state
      }
      const findItem = state.find((_item) => _item.id === item.id)
      if (findItem) {
        findItem.quantity = result
      } else {
        state.push({ ...item, quantity: result })
      }
    }
  }
})

export const { resetData, pickerChange } = mainReducer.actions

export default mainReducer.reducer
