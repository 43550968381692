import { styled } from "@mui/material"
import Box from "@mui/material/Box"
import { Theme } from "@mui/material/styles"

interface ISpace {
  length: string
  smLength?: string
}

export const VSpace = styled("div")<ISpace>(({ theme, length, smLength }) => {
  const style = {
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      height: length,
      minHeight: length
    },
    [theme.breakpoints.down("sm")]: {
      height: smLength || length,
      minHeight: smLength || length
    }
  }
  return style
})

export const HSpace = styled("div")<ISpace>(({ length }) => ({
  flexShrink: 0,
  width: length,
  minWidth: length
}))
interface IDivider {
  depth?: string
  color?: string
}

export const Divider = styled("div")<IDivider>(({ theme, depth, color }) => ({
  width: "100%",
  flexShrink: 0,
  height: depth || "1px",
  backgroundColor: theme.palette.background[color || "gray3"]
}))

interface IRow {
  align?: string
  gap?: string
}

export const Row = styled(Box)<IRow>(({ align, gap }) => ({
  display: "flex",
  position: "relative",
  alignItems: align || "center",
  gap: gap || "unset"
}))

interface IText {
  theme?: Theme
  display?: string
  margin?: string
  cursor?: string
  width?: string
  textalign?: string
  size?: string
  weight?: string
  minwidth?: string
  color?: string
  wordbreak?: string
  whitespace?: string
  textoverflow?: string
  overflow?: string
}

export const Text = styled("p")<IText>(
  ({
    theme,
    display,
    margin,
    cursor,
    width,
    textalign,
    size,
    weight,
    minwidth,
    color,
    wordbreak,
    whitespace,
    textoverflow,
    overflow
  }) => ({
    display: display || "block",
    margin: margin || "0px",
    cursor: cursor || "unset",
    width: width || "unset",
    textAlign: (textalign as any) || "unset",
    fontSize: size || "14px",
    fontWeight: weight || "normal",
    minWidth: minwidth || "unset",
    color: theme.palette.text[color || "black2"],
    wordBreak: (wordbreak as any) || "normal",
    whiteSpace: (whitespace as any) || "normal",
    textOverflow: textoverflow || "normal",
    overflow: overflow || "normal"
  })
)

interface IRwdText {
  color?: string
  sm: {
    [key: string]: string
  }
  md: {
    [key: string]: string
  }
}

export const RwdText = styled("p")<IRwdText>(({ theme, color, sm, md }) => ({
  display: "block",
  margin: "0px",
  color: theme.palette.text[color || "black2"],
  [theme.breakpoints.up("sm")]: {
    ...md
  },
  [theme.breakpoints.down("sm")]: {
    ...sm
  }
}))

interface IFieldLabel {
  size?: string
  weight?: string
  required?: boolean
}

export const FieldLabel = styled("p")<IFieldLabel>(({ theme, size, weight, required }) => {
  const style = {
    fontSize: size || "14px",
    fontWeight: weight || "500",
    margin: "0 0 4px 0",
    color: theme.palette.text.black2,
    whiteSpace: "nowrap" as const
  }
  if (required) {
    style["&::after"] = {
      content: "'*'",
      color: theme.palette.text.blue7
    }
  } else {
    style["&::after"] = {
      content: "''"
    }
  }
  return style
})
